import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { first } from 'rxjs/operators';

import { HttpRouterService } from './_httpRouterService';

@Injectable()
export class ClaimOnService {
	constructor(private http: HttpClient, private httpRouterService: HttpRouterService) {}

	async getJsonBodyDetail(accCallNo: string, userId: string) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/getJsonBodyDetail')];
		return await this.httpRouterService.call(method, url, { AccCallNo: accCallNo, UserId: userId, Mode: 'all', WsId: '' }).pipe(first()).toPromise();
	}

	async saveJsonBodyDetailAccident(accCallNo: string, userId: string, jsonBody: object) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/saveJsonBodyDetail')];
		return await this.httpRouterService
			.call(method, url, { AccCallNo: accCallNo, UserId: userId, WsId: 'accidentInfo', JSON_Body: { accidentInfo: jsonBody } })
			.pipe(first())
			.toPromise();
	}

	async saveJsonBodyDetailInsured(accCallNo: string, userId: string, jsonBody: object) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/saveJsonBodyDetail')];
		return await this.httpRouterService
			.call(method, url, { AccCallNo: accCallNo, UserId: userId, WsId: 'insured', JSON_Body: { insuredInfo: jsonBody } })
			.pipe(first())
			.toPromise();
	}

	async saveJsonBodyDetailParties(accCallNo: string, userId: string, jsonBody: object) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/saveJsonBodyDetail')];
		return await this.httpRouterService
			.call(method, url, { AccCallNo: accCallNo, UserId: userId, WsId: 'parties', JSON_Body: { partiesInfo: jsonBody } })
			.pipe(first())
			.toPromise();
	}

	async saveJsonBodyDetailCasualty(accCallNo: string, userId: string, jsonBody: object) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/saveJsonBodyDetail')];
		return await this.httpRouterService
			.call(method, url, { AccCallNo: accCallNo, UserId: userId, WsId: 'casualties', JSON_Body: { casualtyInfo: jsonBody } })
			.pipe(first())
			.toPromise();
	}

	async saveJsonBodyDetailIncident(accCallNo: string, userId: string, jsonBody: object) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/saveJsonBodyDetail')];
		console.log('jsonBody', jsonBody);
		return await this.httpRouterService
			.call(method, url, { AccCallNo: accCallNo, UserId: userId, WsId: 'incidentInfo', JSON_Body: { incidentInfo: jsonBody } })
			.pipe(first())
			.toPromise();
	}

	async saveJsonBodyDetailSurveyor(accCallNo: string, userId: string, jsonBody: object) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/saveJsonBodyDetail')];
		return await this.httpRouterService
			.call(method, url, { AccCallNo: accCallNo, UserId: userId, WsId: 'surveyor', JSON_Body: { surveyorInfo: jsonBody } })
			.pipe(first())
			.toPromise();
	}

	async getTaskSummary(accCallNoList: Array<string>) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/getTaskSummary')];
		return await this.httpRouterService.call(method, url, { AccCallNoList: accCallNoList }).pipe(first()).toPromise();
	}

	async getSummary(accCallNo: string) {
		const [method, url] = ['GET', this.httpRouterService.backendURLGeneratorAWS('summary/' + accCallNo)];
		return await this.httpRouterService.call(method, url).pipe(first()).toPromise();
	}

	async awsDeletePicture(accCallNo: string, imageLists: Array<string>) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('awsS3/deletePicture')];
		return await this.httpRouterService.call(method, url, { accCallNo, imageLists }).pipe(first()).toPromise();
	}

	async saveJsonBodyDetailConsent(accCallNo: string, userId: string, jsonBody: object) {
		const [method, url] = ['POST', this.httpRouterService.backendURLGeneratorAWS('claim-on/saveJsonBodyDetail')];
		return await this.httpRouterService
			.call(method, url, { AccCallNo: accCallNo, UserId: userId, WsId: 'consentInfo', JSON_Body: { consentInfo: jsonBody } })
			.pipe(first())
			.toPromise();
	}
}
