import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LottieModule } from 'ngx-lottie';
import { DndModule } from 'ngx-drag-drop';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';
import player from 'lottie-web';
import 'hammerjs';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ErrorComponent } from './pages/error/error.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';

import { AnnounceComponent } from './component/announce/announce.component';
import { FooterComponent } from './component/footer/footer.component';

import { HttpRouterService } from './service/api/_httpRouterService';
import { ClaimOnService } from './service/api/claimon.service';
import { MclaimService } from './service/api/mclaim.service';
import { NewCCService } from './service/api/newcc.service';
import { UserService } from './service/api/user.service';

import { JsonFacadeService } from 'src/app/shared/service/ngrx-helper/jsonFacade.service';
import { TaskSummaryService } from 'src/app/shared/service/ngrx-helper/taskSummary.service';

import { SvgService } from 'src/app/shared/service/common/svg.service';

import { ToolsService } from './service/tools/tools.service';
import { DisplayNameFilterPipe } from './service/tools/filterPipe';
import { TabsStepBackComponent } from './component/tabs-step-back/tabs-step-back.component';
import { FooterSaveJsonComponent } from './component/footer-save-json/footer-save-json.component';
import { DriverLicenceComponent } from './component/driver-licence/driver-licence.component';
import { IdCardComponent } from './component/id-card/id-card.component';
import { PictureUploadComponent } from './component/picture-upload/picture-upload.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { DocumentVideoUploadComponent } from './component/document-video-upload/document-video-upload.component';
import { environment } from 'src/environments/environment.localTest';
import { LoadingComponent } from './component/loading/loading.component';
import { ForgotPasswordOutsourceComponent } from './pages/forgot-password-outsource/forgot-password-outsource.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'error',
				component: ErrorComponent,
				data: {
					title: 'Error Page',
				},
			},
			{
				path: 'login',
				component: LoginComponent,
				data: {
					title: 'Login Page',
				},
			},
			{
				path: 'register',
				component: RegisterComponent,
				data: {
					title: 'Register Page',
				},
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				data: {
					title: 'Forgot password Page',
				},
			},
			{
				path: 'forgot-password-outsource',
				component: ForgotPasswordOutsourceComponent,
				data: {
					title: 'Forgot password Page',
				},
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent,
				data: {
					title: 'Reset password Page',
				},
			},
			{
				path: 'maintenance',
				component: MaintenanceComponent,
				data: {
					title: 'Maintenance Page',
				},
			},
			{
				path: 'coming-soon',
				component: ComingSoonComponent,
				data: {
					title: 'Coming Soon Page',
				},
			},
		],
	},
];

export function playerFactory() {
	return player;
}

@NgModule({
	exports: [
		CommonModule,
		NgbModule,
		RouterModule,
		PerfectScrollbarModule,
		NgxSpinnerModule,
		LottieModule,
		AgmCoreModule,
		DndModule,
		DisplayNameFilterPipe,
		WebcamModule,
		ImageCropperModule,

		NzIconModule,
		NzLayoutModule,
		NzMenuModule,
		NzButtonModule,
		NzGridModule,
		NzImageModule,
		NzInputModule,
		NzTypographyModule,
		NzProgressModule,
		NzAvatarModule,
		NzCardModule,
		NzSpaceModule,
		NzDividerModule,
		NzSkeletonModule,
		NzDropDownModule,
		NzTagModule,
		NzModalModule,
		NzSelectModule,
		NzRadioModule,
		NzCheckboxModule,
		NzSwitchModule,
		NzStepsModule,
		NzFormModule,
		NzSpinModule,
		NzTimelineModule,
		NzInputNumberModule,
		NzTimePickerModule,
		NzPopoverModule,
		NzListModule,
		NzMessageModule,
		NzUploadModule,
		NzAlertModule,
		ScrollingModule,
		DragDropModule,

		LoginComponent,
		RegisterComponent,
		MaintenanceComponent,
		ForgotPasswordComponent,
		ErrorComponent,
		ComingSoonComponent,

		AnnounceComponent,
		FooterComponent,
		TabsStepBackComponent,
		DriverLicenceComponent,
		IdCardComponent,
		PictureUploadComponent,
		FooterSaveJsonComponent,
		ChangePasswordComponent,
		DocumentVideoUploadComponent,
		LoadingComponent,
		NzEmptyModule,
	],
	imports: [
		RouterModule.forChild(routes),
		CommonModule,
		RouterModule,
		FormsModule,
		AgmCoreModule.forRoot({
			apiKey: environment.googlemapApiKey,
			libraries: ['places'],
		}),

		ReactiveFormsModule,
		OverlayModule,
		NgbModule,
		PerfectScrollbarModule,
		NgxSpinnerModule,
		LottieModule.forRoot({ player: playerFactory }),
		DndModule,
		WebcamModule,
		ImageCropperModule,

		NzIconModule,
		NzLayoutModule,
		NzMenuModule,
		NzButtonModule,
		NzGridModule,
		NzImageModule,
		NzInputModule,
		NzInputNumberModule,
		NzTypographyModule,
		NzProgressModule,
		NzAvatarModule,
		NzCardModule,
		NzSpaceModule,
		NzDividerModule,
		NzSkeletonModule,
		NzDropDownModule,
		NzTagModule,
		NzModalModule,
		NzSelectModule,
		NzRadioModule,
		NzCheckboxModule,
		NzSwitchModule,
		NzStepsModule,
		NzFormModule,
		NzSpinModule,
		NzTimelineModule,
		NzTimePickerModule,
		NzPopoverModule,
		NzListModule,
		NzMessageModule,
		NzUploadModule,
		NgOtpInputModule,
		NzSegmentedModule,
		NzEmptyModule,
	],
	declarations: [
		LoginComponent,
		RegisterComponent,
		MaintenanceComponent,
		ForgotPasswordComponent,
		ForgotPasswordOutsourceComponent,
		ErrorComponent,
		ComingSoonComponent,

		AnnounceComponent,
		FooterComponent,
		TabsStepBackComponent,

		DisplayNameFilterPipe,
		DriverLicenceComponent,
		IdCardComponent,
		PictureUploadComponent,
		ResetPasswordComponent,
		FooterSaveJsonComponent,
		ChangePasswordComponent,
		DocumentVideoUploadComponent,
		LoadingComponent,
	],
	providers: [HttpRouterService, ClaimOnService, MclaimService, NewCCService, ToolsService, SvgService, UserService, JsonFacadeService, TaskSummaryService],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [HttpRouterService, ClaimOnService, MclaimService, NewCCService, ToolsService, SvgService, UserService, JsonFacadeService, TaskSummaryService],
		};
	}
}
