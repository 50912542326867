import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';

import { BreakpointService } from 'src/app/shared/service/form-helper/breakpoint.service';
import { Method, MethodFunction, SubMethod } from 'src/app/shared/model/common/page-method';

@Component({
	selector: 'app-tabs-step-back',
	templateUrl: './tabs-step-back.component.html',
	styleUrls: ['./tabs-step-back.component.sass'],
})
export class TabsStepBackComponent implements OnInit {
	datas: any = {};
	_isViewportDesktop: boolean = true;

	@Input() _location: Method = '';
	@Input() _func: MethodFunction = '';
	@Input() _label: string = '';
	@Input() _isSaveVisible?: boolean = true;
	@Input() _isSaveNewccVisible?: boolean = false;
	@Input() _canSave?: boolean = true;
	@Input() _canSaveNewcc?: boolean = false;
	@Input() _canDeleteVisible?: boolean = false;
	@Input() _subMethod?: SubMethod = '';
	@Input() _save?: (datasObj: any) => void;

	@Output() _callback? = new EventEmitter<any>();
	@Output() _callbackDelete? = new EventEmitter<any>();
	@Output() _callbackGoto? = new EventEmitter<any>();

	constructor(private location: Location, private scroller: ViewportScroller, private breakpointService: BreakpointService) {
		this.breakpointService.isSmallScreen$.subscribe(isSmallScreen => {
			this._isViewportDesktop = !isSmallScreen;
		});
	}

	ngOnInit(): void {}

	goto() {
		if (this._func === 'consent-management') {
			this.callbackGoTo();
		} else {
			this.location.back();
			const position: string = sessionStorage.getItem('position');

			if (position) {
				setTimeout(() => {
					const ele = document.getElementById(position).getBoundingClientRect().top;
					this.scroller.scrollToPosition([0, ele - 153]);
					sessionStorage.removeItem('position');
				}, 200);
			}
		}
	}

	save() {
		this._save(this.datas);
	}

	deleteEvent() {
		this._callbackDelete.emit();
	}

	callback() {
		this._callback.emit();
	}

	callbackGoTo() {
		this._callbackGoto.emit();
	}
}
