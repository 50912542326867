import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OpenTelemetryInterceptorModule, CompositePropagatorModule } from '@jufab/opentelemetry-angular-interceptor';
import packageJson from '../../package.json';

import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { counterReducer } from './redux/counter/counter.reducer';
import { newccConstantReducer } from './redux/newccConstant/newccConstant.reducer';
import { jsonBodyDetailReducer } from './redux/jsonBodyDetail/jsonBodyDetail.reducer';
import { taskSummaryReducer } from './redux/taskSummary/taskSummary.reducer';

import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';

import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { UserLayoutBetaComponent } from './layouts/user-layout-beta/user-layout-beta.component';

import { AuthService } from './shared/auth/authentication.service';
import { AuthUserGuard, AuthUserBetaGuard } from './shared/auth/helper/auth.guard';
import { authInterceptorProviders } from './shared/auth/helper/auth.interceptor';
import { CookieService } from 'ngx-cookie-service';
registerLocaleData(en);

const appName: string = packageJson.name;

@NgModule({
	declarations: [AppComponent, ContentLayoutComponent, UserLayoutComponent, UserLayoutBetaComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({ progressBar: true, maxOpened: 1, autoDismiss: true }), // ToastrModule added
		AppRoutingModule,
		SharedModule,
		FormsModule,
		HttpClientModule,
		StoreModule.forRoot({
			count: counterReducer,
			newccConstant: newccConstantReducer,
			jsonBody: jsonBodyDetailReducer,
			taskSummary: taskSummaryReducer,
		}),
		IconsProviderModule,

		CompositePropagatorModule,
	],
	exports: [RouterModule],
	providers: [AuthService, AuthUserGuard, AuthUserBetaGuard, { provide: NZ_I18N, useValue: en_US }, authInterceptorProviders, CookieService],
	bootstrap: [AppComponent],
})
export class AppModule {}
